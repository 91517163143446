import React from "react"
import { instagramAccountUrl } from "../../config"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faInstagram } from "@fortawesome/free-brands-svg-icons"
// import { Link } from "gatsby"

import Layout from "../Layout/Layout"
import LogoImage from "../LogoImage"
import SEO from "../seo"

import * as _ from "./IndexPageContent.module.css"

const IndexPageContent = () =>
  <Layout>
    <SEO title="Home" />
    <div className={_.content}>
      <a href={instagramAccountUrl}><LogoImage /></a>
      <div className={_.body}>
        <div className={_.comingSoon}>Coming soon</div>
        {/*<a href={instagramAccountUrl}><FontAwesomeIcon icon={faInstagram} /></a>*/}
      </div>
    </div>
    {/*<h1>Hi people</h1>*/}
    {/*<p>Welcome to your new Gatsby site.</p>*/}
    {/*<p>Now go build something great.</p>*/}
    {/*<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>*/}
    {/*  <Image />*/}
    {/*</div>*/}
    {/*<Link to="/page-2/">Go to page 2</Link> <br />*/}
    {/*<Link to="/using-typescript/">Go to "Using TypeScript"</Link>*/}
  </Layout>

export default IndexPageContent
